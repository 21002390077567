import React from 'react'
import { graphql } from 'gatsby'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'
import { default as ArchiveBlock } from '../components/Archive/Archive'
import TopBanner from '../components/Banners/TopBanner/TopBanner'
import FooterBanner from '../components/Banners/FooterBanner/FooterBanner'
import PageMeta from '../components/PageMeta/PageMeta'
import { type IArchiveQueryData } from '../graphql/queries/ArchiveQuery'
import Leaderboard from '../components/Banners/Leaderboard/Leaderboard'

export const query = graphql`
  query ($id: ID!) {
    wpgraphql {
      category(id: $id, idType: ID) {
        databaseId
        name
        description
        slug
        link
        seo {
          metaDesc
          title
          twitterTitle
          twitterDescription
          metaKeywords
        }
        parent {
          node {
            name
            uri
          }
        }
        uri
        sponsoredBy
        sponsoredLogo
        prefix
        children {
          nodes {
            name
            uri
          }
        }
      }
      generalSettings {
        egSettingGeo
        title
        siteId
        egSettingTwitter
      }
      adStarCodes {
        id
        adslot
      }
    }
  }
`

interface ArchiveProps {
  data: IArchiveQueryData
}

const Archive = ({
  data: {
    wpgraphql: { adStarCodes, category, generalSettings }
  }
}: ArchiveProps) => {
  if (!category) return null

  const parentCategory = category?.parent?.node
  const isBestPlacestoLive = parentCategory?.name
    ? Boolean(parentCategory.name === 'Best Places to Live')
    : false
  const { egSettingGeo, title, siteId } = generalSettings

  const getPageType = () => {
    if (isBestPlacestoLive) return 'bptl'

    if (!parentCategory) return 'topcategory'

    return 'subcategory'
  }

  const adsToShow = adStarCodes.filter(
    ad =>
      ad?.adslot?.toLowerCase().includes('mpu') ||
      ad?.adslot?.toLowerCase().includes('lb')
  )

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: category.name,
          description: category.seo?.metaDesc ?? category.description,
          url: category.link
        }}
      />
      <Header siteId={siteId} siteName={title} />
      <TopBanner
        ads={adsToShow}
        showMPUs
        {...(category?.name && { category: category.name })}
        {...(!parentCategory && { subcategory: category?.name || '' })}
        pageType={getPageType()}
        showFooterLB={Boolean(isBestPlacestoLive)}
      />
      <Leaderboard adSlot={'TopLB'} sticky />
      <ArchiveBlock
        category={category}
        parentCategory={category?.parent?.node}
        isBestPlacestoLive={isBestPlacestoLive}
        countyLatLon={egSettingGeo}
        pagination={1}
        siteId={siteId ?? null}
      />
      {isBestPlacestoLive && <FooterBanner />}
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default Archive
